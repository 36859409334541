import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width ? props.width : `100%`,
        padding: props => props.padding ? props.padding : `3rem`,
        borderRadius: props => props.borderRadius ? props.borderRadius : `1rem`,
        boxShadow: `0px 20px 30px rgba(17,122,243,0.05)`,
        backgroundColor: theme.palette.background.paper
    }
}));

export { useStyles }